<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <router-link
        :to="{ path: '/videopostprocessing/theme/' + themeId + '/layoutimageelement' }"
        class="nav-link"
        active-class="active"
        tag="a"
      >
        {{ $t('layoutImages') }}
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{ path: '/videopostprocessing/theme/' + themeId + '/layouttextelement' }"
        class="nav-link"
        active-class="active"
        tag="a"
      >
        {{ $t('layoutTexts') }}
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{ path: '/videopostprocessing/theme/' + themeId + '/userdependentimageelement' }"
        class="nav-link"
        active-class="active"
        tag="a"
      >
        {{ $t('userImages') }}
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{ path: '/videopostprocessing/theme/' + themeId + '/userdependenttextelement' }"
        class="nav-link"
        active-class="active"
        tag="a"
      >
        {{ $t('userTexts') }}
      </router-link>
    </li>
    <li class="nav-item">
      <router-link
        :to="{ path: '/videopostprocessing/theme/' + themeId + '/sound' }"
        class="nav-link"
        active-class="active"
        tag="a"
      >
        {{ $t('menu.sounds') }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ThemeElementTabs",
  props: {
    themeId: {
      type: String,
      required: true
    }
  }
}
</script>

